import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import SubmenuItem1Page from './SubmenuItem1Page';

function App() {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [utsOccupancyData, setUtsOccupancyData] = useState(null);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleSubmenuItemClicked = () => {
    setSidebarVisible(false);
  };

  const handleUTSOccupancyClick = () => {
    console.log("Hi Raj");
    fetch('https://www.satsweb.indianrail.gov.in/GetCurrentProfileByTrainNumberPublish_RestApi?TrainNumber=12301')
      .then(response => response.json())
      .then(data => {
        setUtsOccupancyData(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <Router>
      <div className="App">
        <div className="top-bar">
          <h1>Train Planner</h1>
        </div>
        <div className="chat-window">
          <div className={sidebarVisible ? 'sidebar' : 'sidebar hidden'}>
            <div className="menu">
              <ul>
                <li><Link to="/" onClick={handleUTSOccupancyClick}>UTS Ticket Sales</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>PRS Occupancy</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>O-D Selection</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Route Selection</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Sch. Generator</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Stoppage Time</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Allowances</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Path Availability</Link></li>
                <li><Link onClick={handleUTSOccupancyClick}>UTS Occupancy</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Pit Line Availability</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Rake Availability</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Crew Availability</Link></li>
                <li><Link to="/" onClick={handleSubmenuItemClicked}>Loco Availability</Link></li>
                <li><Link to="/" onClick={toggleSidebar}></Link></li>
                {/* Add more menu items as needed */}
              </ul>
            </div>
          </div>
          <div className="chat-container">
            <Routes>
              <Route path="/submenu1" element={<SubmenuItem1Page />} />
            </Routes>
            {utsOccupancyData && (
              <div className="uts-occupancy-data">
                <h2>UTS Occupancy Data</h2>
                <pre>{JSON.stringify(utsOccupancyData, null, 2)}</pre>
              </div>
            )}
          </div>
          <div className="toggle-sidebar" onClick={toggleSidebar}>
            {sidebarVisible ? '>' : '<'}
          </div>
        </div>
        <div className="bottom-bar">
          <h1>SATSaNG</h1>
        </div>
      </div>
    </Router>
  );
}

export default App;
